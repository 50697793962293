
  import {
    Options,
    Vue
  } from 'vue-class-component';

  @Options({

    methods: {
      foo: function () {
        console.log("bar");
      }
    },
    data: function () {
      return {
        initialized: false,
      }
    },
    mounted: function () {
      this.initialized = true;
    
    },
    // computed : {
    //   currentUser() {
    //     return this.$store.state.currentUser;
    //   }
    // }
  })
  export default class AppMain extends Vue {

  }


import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    // msg: String
  },
  data : () => {
    return {
        ready : false
    }
  },
  mounted : function(){
    setTimeout(() => {
        this.ready = true;
    },500);
  }
})
export default class PochitamaMainvis extends Vue {
//   msg!: string
}

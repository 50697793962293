
  import {
    Options,
    Vue
  } from 'vue-class-component';
  import PochitamaMainvis from "@/components/PochitamaMainvis.vue";

  @Options({
    components: {
      PochitamaMainvis
    },
    methods: {
      transitionLeave: function () {
        console.log("call leave event")
      },
      transitionEndBg: function () {
        this.transitions.bg = true;
      }
    },
    data: function () {
      return {
        siteEnter: false,
        drawer : false,
        navs : [
          {to:"/",label:"HOME"},
          {to:"/news",label:"NEWS"},
          {to:"/about-pochi",label:"ABOUT:pochi."},
          {to:"/about-tama",label:"ABOUT:tama."},
          {to:"/works",label:"WORKS"},
          {to:"/form",label:"FORM"},
          {to:"/help",label:"HELP"},
        ],
        platform : "pc",
        transitions: {
          bg: false,
        }
      }
    },

    mounted: function () {
      setTimeout(() => {
        this.siteEnter = true;
      }, 100);

      // detect platforms
      (()=> {
        let platform = navigator.platform.toLowerCase();
        let vendor = navigator.vendor.toLowerCase();
        let useragent = navigator.userAgent;
        let tmp = [];
        tmp.push(platform);
        if (platform == 'iphone' || platform == 'ipad' ){
          tmp.push("ios");
        }else if (platform.match('mac')){
          tmp.push("macos");
        }else if (platform.match('win')){
          tmp.push("windows");
        }

        if (vendor.match("apple")){
          tmp.push("webkit");
        }else if (vendor.match("google")){
          tmp.push("chromium");
        } else if(useragent.match("firefox")) {
          tmp.push("gecko");
        }

        this.platform = tmp.join(" ");
      })();
      
    },



  })
  export default class AppMain extends Vue {

  }
